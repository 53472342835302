import React, { useEffect } from 'react'
import {Grid, Skeleton} from '@mui/material';
import { Rating,Box } from "@mui/material";

import ContentReviews from './ContentReviews';

const ReviewRow = ({item}) => {
  return (
    <>
  
        <Grid item xs={12} sx={{mb:4}} className='section-review-star-states'>
            <div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        {item ? <img style={{ marginRight: "17px", maxWidth: "32px" }} src={item.source.logo ? item.source.logo : "/admin-icons/feedback-irevu.svg"} /> :  <Skeleton style={{ marginRight: "17px" }} sx={{width: "40px",height:"80px"}} />}
                        <span className='reviews-text-head'>{item ? item.author.name : <Skeleton sx={{width:"100px", height: '20px'}} />}</span>
                    </div>
                    <span className='reviews-text-date'>{item ? new Date(item.published_at_formatted).toLocaleString('en-US',{  day: 'numeric',year: 'numeric',month: 'short',day: 'numeric',hour:"numeric",minute:"numeric"}) : <Skeleton sx={{width:"40px"}}/>}</span>
                </div>
            </div>
            <Box  sx={{ width: 1 }}>
                <div style={{ marginTop: "10px" }}>
                    {item ? <Rating icon={<img src={"/admin-images/filled-icon.svg"} className="custom-rating-sm-ic"/>} emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-sm-ic"/>} value={item.rating} readOnly /> : <Skeleton/>}
                    {/* <Rating value={item.rating} readOnly /> */}
                </div>
                {item ? <ContentReviews content={item.content}/> : <Skeleton/>}
            </Box>
        </Grid>
    </>
  )
}

const ReviewRowMemo = React.memo(ReviewRow)

export default ReviewRowMemo