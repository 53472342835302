
import React, {Suspense, useRef, useState, useEffect} from 'react';
import Sidebar from './components/Admin/Sidebar';
import Footer from './components/Footer';
import Navbar from './components/Admin/Navbar';
import { useSelector } from 'react-redux';
import Banner from './components/Admin/parts/banner';
import VideoTutorialButton from './components/Admin/helpers/VideoTutorialButton';
import IreviewFooter from "./components/IreviewFooter";
import ProtectedRoute from './components/Helpers/protectedRoute';
import LayoutLoading from './Layout_Loading';
import { useDispatch } from 'react-redux';
import { clearDashboardStats, clearReviews, clearTags, clearSources, clearLocationConnections } from './redux/actions/admin-index';

const Layout = ({ title, permissions, children, all , groups, days }) => {
  const dispatch = useDispatch()
  const [height, setHeight] = useState(0)
  const isInitialRender = useRef(true);
  const divRef = useRef(null);
  const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)
  const demoReducer = useSelector(state => state.demoReducer.demo)
  const cancelReducer = useSelector(state => state.cancelReducer.cancel)
  const location = useSelector(state => state.locationSelected.location)
  const groupSelected = useSelector(state => state.groupSelected.group)
  const user = useSelector(state => state.getUserInfo.user)

  useEffect(() => {
    if (isInitialRender.current) {
        isInitialRender.current = false;
      } else {
        dispatch(clearDashboardStats())
        dispatch(clearReviews())
        dispatch(clearTags())
        dispatch(clearSources())
        dispatch(clearLocationConnections())
      }
}, [location, groupSelected])

  return (
    <ProtectedRoute title={title} permissions={permissions}>
    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer))  && user && <div ref={divRef} id="banner">
        <Banner setHeight={setHeight} days={days} />
    </div>
    }
      <Navbar height={height} title={title} all={all} groups={groups} />
      <Suspense></Suspense>
      <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }}>
        <Suspense fallback={<LayoutLoading/>}>
          <VideoTutorialButton title={title} />
          {children}
        </Suspense>
      </div>
      <Sidebar height={height} />
    </ProtectedRoute>
  );
};

export default Layout;
