import axios from 'axios';
import { baseUrlDev } from './baseUrl';
import { getLocalUser } from '../TokenControl/parts/useLocalUser';

const defaultHeaders = {
    'Authorization': `Bearer ${getLocalUser().token}`, 
    'APP-VERSION': 'react' 
}

class AppUrl {
    static getData(url, params = {}, additionalHeaders = {}, onSuccess = () => {}, onFailure = () => {}) {
        const headers = { ...defaultHeaders, ...additionalHeaders };
        axios.get(`${baseUrlDev}${url}`, { params }, {headers})
            .then(response => onSuccess(response.data))
            .catch(error => onFailure(error));
    }

    static postData(url, data = {}, additionalHeaders = {}, onSuccess = () => {}, onFailure = () => {}) {
        const headers = { ...defaultHeaders, ...additionalHeaders };
        axios.post(`${baseUrlDev}${url}`, data, {headers})
            .then(response => onSuccess(response.data))
            .catch(error => onFailure(error));
    }
}

export default AppUrl;