import React, { useEffect, useRef, useState } from 'react'
import { Container, Grid, FormControl, InputLabel, Select, MenuItem, Box, Rating, Skeleton,Chip } from '@mui/material'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {IoIosArrowDown} from "react-icons/io"

// custom component
import ReviewProgressBar from './parts/ReviewProgressBar';
import ReviewList from './parts/ReviewList';
import WriteReview from './parts/WriteReview';
import SmallSelect from '../Helpers/SmallSelect';

import { IoIosCloudDone } from 'react-icons/io'
import {AiFillStar} from 'react-icons/ai';
import '../../assets/about.css';
import MissingAbout from './parts/MissingAbout';
import { useNavigate } from 'react-router-dom';

const About = () => {
    let { name } = useParams();
    const [companyData, setCompanyData] = useState({
        name: null,
        location: null,
        logoUrl: null,
        website: null,
        company_id: null,
        longitude: 0,
        latitude: 0,
        working_hours: {
            to: null,
            from: null
        }
    });
    const [reviewStats, setReviewStats] = useState({
        total: 0,
        rating: [0, 0, 0, 0, 0],
        avg: 0
    });
    const navigation = useNavigate();



    const companyDataSelect = useSelector(state => state.companieAboutReducer.companie)

    useEffect(() => {
   
        if (!companyDataSelect) return;
        if (!companyDataSelect?.reviewStars) return;
        //console.log('companyDataSelectcompanyDataSelectcompanyDataSelect:',companyDataSelect.company.categories);
        
        let ratingArray = [companyDataSelect.reviewStars.stars["1 star"].count,
        companyDataSelect.reviewStars.stars["2 star"].count,
        companyDataSelect.reviewStars.stars["3 star"].count,
        companyDataSelect.reviewStars.stars["4 star"].count,
        companyDataSelect.reviewStars.stars["5 star"].count];

        setCompanyData({
            ...companyData,
            location: companyDataSelect?.company?.address,
            name: companyDataSelect?.company?.name,
            website: companyDataSelect?.company?.website,
            logoUrl: companyDataSelect?.company?.logo,
            working_hours: companyDataSelect?.company?.working_hours,
            email: companyDataSelect?.company?.email,
            longitude: companyDataSelect?.company?.longitude,
            latitude: companyDataSelect?.company?.latitude,
        });

        let avgCalc = ratingArray.reduce((a, c, i) => a += c * (i + 1), 0)  / companyDataSelect.reviewStars.total;
        
        setReviewStats({
            total: companyDataSelect.reviewStars.total,
            rating: ratingArray,
            avg: isNaN(avgCalc) ? 0.0 : avgCalc
        });
    }, [companyDataSelect?.company?.name]);

    const [sortby, setSortby] = useState('latest');
    const handleChangeSort = (event) => {
        setSortby(event.target.value);
    };

    return (
        <>
            <div className="about-section">
                <Helmet>
                    <title>Company Details - iReview</title>
                    <meta property="og:title" content="Company Details - iReview"/>
                    <meta property="og:url" content={`https://iReview.com/companies/${name}`} />
                </Helmet>
                <MissingAbout />
                <div className='about-page-header'>
                    <a href="/"><img alt="logo" src={"/admin-images/iReview.svg"} /></a>
                    {companyDataSelect?.company?.accredited !== 1 && <div>Want to get Accredited? <a href="https://irevu.com/request-demo/">Click Here.</a></div>}
                </div>
                <section className="intro-section">
                    {companyDataSelect && companyDataSelect.company.banner ? <img className='uploaded-banner' src={companyDataSelect.company.banner} alt="banner" /> : <img src={"/admin-images/default-banner.svg"} alt="banner" />}
                </section>
                <div className='ribben'>
                    <div className='ribben-container'>
                            <div className="holder-image">
                                {!companyData.name ?
                                    <Box className='logo-container-about'>
                                        <Skeleton variant="rectangular" sx={{ background: "#ffffffee", height: "130px", width: "130px" }} />
                                    </Box> : companyData.logoUrl && <img className='logo-container-about' onError={(e)=>{
                                       e.target.remove();
                                    }} src={companyData.logoUrl} alt="" />}
                            </div>
                            <div className="holder-text">
                                <h1>{companyData?.name ? companyData.name : <Skeleton sx={{ background: "#ffffff" }} variant="text" />}</h1>
                                <p>{companyData?.name ? companyData?.location : <Skeleton sx={{ background: "#ffffff" }} />}</p>
                                <div className="rating-stars stars-stats">
                                    {companyData?.name ? <>
                                        {true && <>
                                            <Rating icon={<img src={"/admin-images/filled-icon.svg"} className="custom-rating-ic"/>} emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-ic"/>} name="half-rating-read" value={parseFloat(reviewStats.avg.toFixed(2))} precision={0.5} readOnly style={{marginRight:"10px"}} />
                                            <span className="rating-text">
                                                {reviewStats.avg !== 0.0 ? <>
                                                    {reviewStats.avg.toFixed(1)}/5.0 - Based on {reviewStats.total} reviews
                                                </> : <> No reviews</>}
                                            </span>
                                        </>}
                                    </> : <Skeleton sx={{ background: "#ffffff", width: "400px" }} />}
                                </div>
                            </div>

                            {companyDataSelect?.company?.name ? 
                                <div className="ireview-accredited-container">
                                        <Box>
                                            {companyDataSelect?.company?.accredited !== 1 ? 
                                            <span  className="mr-10 accredited-page">
                                                iReview Unaccredited
                                            </span> : 
                                            <span className="mr-10  ">
                                            <IoIosCloudDone style={{ marginBottom: "-3px" }} /> iReview Accredited
                                        </span>
                                            }
                                        
                                        </Box>
                                        <Box>
                                            {
                                                companyDataSelect?.company?.claimed !== 1 ? <a href="https://irevu.com/request-demo/" className="claimed-page">Claim this page</a> : <span className='claimed-page'>Claimed</span>
                                            }
                                        </Box>
                                </div> : 
                                <div className="ireview-accredited-container">
                                    <Skeleton style={{height: "38px", width: '200px', marginRight: "10px", transform: "unset"}}/>
                                    <Skeleton style={{height: "38px", width: '150px', transform: "unset"}}/>
                                </div>
                            }
                        </div>
                </div>
           
                <div className="section-review">
                    <Grid container spacing={4}>
                        <Grid item md={8} xs={12}>
                            <div className='company-reviews-container'>
                               <div className='total-reviews-holder'>
                                    <img src={"/admin-images/bg-star.svg"} />
                                    <div>
                                        <span>
                                            {reviewStats.avg.toFixed(1)}
                                        </span>
                                        <span>
                                            {reviewStats.total} reviews
                                        </span>
                                    </div>
                               </div>
                               <div>
                                    {reviewStats.rating.map((item, index) => {
                                        return companyData?.name ?
                                            <ReviewProgressBar key={Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)} label={`${index + 1}`} value={item} total={reviewStats.total} /> :
                                            <Skeleton key={Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)} />
                                    }).reverse()}
                               </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div>
                                <WriteReview setSortby={setSortby} companyName={companyData.name} irevu={companyDataSelect?.company?.is_irevu}/>
                            </div>
                        </Grid>

                    </Grid>
                </div>
                <div className="section-review">
                    <Grid container spacing={4} className="change-order-mob">
                        <Grid item md={8} xs={12}>
                            <Grid container sx={{ mb: 4 }} className="align-items-end">
                                <Grid item xs={6}>
                                    <div className="title-decor">Reviews</div>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{float: "right"}}>
                                        <label className='select-label-up'>Sort by:</label>
                                        <Select
                                            value={sortby.trim()}
                                            onChange={handleChangeSort}
                                            input={<SmallSelect/>}
                                            IconComponent={IoIosArrowDown}
                                        >
                                            <MenuItem value={"latest"}>Latest</MenuItem>
                                            <MenuItem value={"oldest"}>Oldest</MenuItem>
                                            <MenuItem value={"highest"}>Highest Rating</MenuItem>
                                            <MenuItem value={"lowest"}>Lowest Rating</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container>
                                {/* Generated list */}
                                <ReviewList sort={sortby} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className='section-review-star-map'>
                                <div style={
                                    { width: "100%" }
                                }>

                                    {companyData?.name ?
                                        (companyData?.latitude ? <div dangerouslySetInnerHTML={{
                                            __html: ` <iframe  width="100%" height="200" 
                                    frameborder="0" 
                                    scrolling="no" 
                                    marginheight="0" 
                                    marginwidth="0" 
                                    src="https://maps.google.com/maps?q=${companyData.latitude + ',' + companyData.longitude}&hl=eng&z=14&amp;output=embed"
                                    >
                                    </iframe>
                                    
                                    ` }} >
                                        </div> : <></>)
                                        : <>
                                            <Skeleton sx={{ height: "200px" }} />
                                        </>}

                                </div>
                                {companyData?.name &&  <a href={companyData?.website} className="visit-web">Visit Website</a>}
                              
                                <h4>Information</h4>
                                <div className="address-icon-bef">
                                    <div className='head-text-info'>Address</div>
                                    <div className='body-text-info'>{companyData?.name ? (companyData?.location || "None") : <Skeleton />}</div>
                                </div>
                                <div className='hours-icon-bef'>
                                    <div className='head-text-info'>Hours of Operation</div>
                                    {companyData?.working_hours?.from ?
                                        <div className='body-text-info'>From {companyData.working_hours.from} to {companyData.working_hours.to}</div>
                                        : (companyData?.name ? <div className='body-text-info'>None</div> : <Skeleton />)}
                                </div>
                                {companyDataSelect?.company?.categories?.length > 0 && 
                                    <div className='categories-icon-bef'>
                                    <div className="head-text-info">Categories</div>
                                        <div className='body-text-info'>{companyDataSelect?.company?.categories?.map((item,index)=>{
                                            return <Chip  sx={{mb:1,mr:1}} onClick={()=>{navigation('/categories/'+item.slug)}} key={index} label={item.name} />
                                        })}
                                    </div>
                                </div>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* <Container maxWidth="xl">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img height="40px" src={logoiReview} />

                        <span className="a-nodecorate">
                            <a className="a-footer" href="#">Terms and Conditions</a>
                            <a className="a-footer" href="#">Privacy</a>
                            <a className="a-footer" href="#">{new Date().getFullYear()} iReview</a>
                        </span>
                    </Box>
                </Container> */}
            </div>

        </>
    )
}

export default About